<template>
    <div class="list-page">
      <el-button
        class="top-btn"
        type="primary"
        size="small"
        @click="openAddCategory = true"
        >新增
      </el-button>
      <list-filter
        :filterForm="filterForm"
        :uploadFilter="ok"
        :ids="selectArr"
        :page="pageData.current_page"
      />
      <!-- <list-tabs
        v-model="filterForm.tab"
        :tabs="tabArr"
        :isLoading="loading"
        @tab-click="handleClick"
      >
      </list-tabs> -->
      <div class="list-content" style="margin-top: 20px" v-loading="loading">
        <div class="list-btn" v-if="filterForm.tab === 'normal'">
          <el-button type="danger" size="small" @click="handleDel"
            >批量删除</el-button
          >
        </div>
        <div class="list-btn" v-if="filterForm.tab === 'deleted'">
          <el-button type="success" size="small" @click="handleReduct"
            >还原</el-button
          >
          <el-button type="danger" size="small" @click="handleDel"
            >批量删除</el-button
          >
        </div>
        <el-table
          :data="tbody"
          class="thead-light"
          row-key="id"
          id="sortTable"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
        >
          <!-- 勾选-->
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button-group v-if="filterForm.tab == 'deleted'">
                <el-button size="small" @click="ReductRow(scope.row)" type="text">
                  还原
                </el-button>
                <el-button size="small" @click="deleteRow(scope.row)" type="text"
                  >删除</el-button
                >
              </el-button-group>
              <el-button-group v-if="filterForm.tab == 'normal'">
                <el-button size="small" @click="moveRow(scope.row)" type="text">
                  转移
                </el-button>
                <el-button size="small" @click="deleteRow(scope.row)" type="text"
                  >删除</el-button
                >
              </el-button-group>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column
              show-overflow-tooltip
              :prop="th.prop"
              :label="th.label"
              :min-width="th.minWidth || ''"
              :key="index"
              :sortable="th.sortable"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-if="['is_recommend', 'is_show'].includes(th.prop)"
                  :false-label="0"
                  :true-label="1"
                  v-model="tbody[scope.$index][th.prop]"
                  @change="changeStatus(scope.row, th.prop)"
                >
                </el-checkbox>
  
                <span
                  v-else-if="th.prop === 'sort'"
                  class="el-icon-sort my-handle cursor-pointer"
                />
  
                <PopoverInputEdit
                  v-else-if="th.prop === 'name'"
                  v-model="scope.row.name"
                  @confirm="changeStatus(scope.row, th.prop)"
                >
                </PopoverInputEdit>
                <!-- 普通 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
      <AddCategory v-model="openAddCategory" :updateList="refreshList" />
      <TransferClassify
        v-model="showTransfer"
        :Data="TransferData"
        :categoryList="tbody"
        :updateData.sync="TransferData"
        :updateList="refreshList"
      />
    </div>
  </template>
  
  <script>
  import listFilter from "@/modules/employ/components/employ-career-category-list/ListFilter";
  import TransferClassify from "@/modules/employ/components/employ-career-category-list/TransferClassify";
  import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
  import Pagination from "@/base/components/Default/Pagination";
  
  import Sortable from "sortablejs/modular/sortable.core.esm.js";
  
  import {
    categoryList,
    saveCategory,
    updateSort,
    softDelete,
    Delete,
    putBack,
    saveCategorySort,
  } from "@/modules/employ/api/employ-career-category-list.js";
  import AddCategory from "@/modules/employ/components/employ-career-category-list/AddCategory.vue";
  export default {
    data() {
      return {
        tabArr: [
          { label: "全部", name: "normal" },
          { label: "回收站", name: "deleted" },
        ],
        loading: false, //加载
        thead: [
          { label: "拖拽排序", prop: "sort", minWidth: 80 },
          // { label: "ID", prop: "id", minWidth: 80 },
          { label: "岗位类型", prop: "name", minWidth: 150 },
          {
            label: "岗位数量",
            prop: "careers_count",
            minWidth: 120,
            sortable: true,
          },
          {
            label: "创建时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
          // { label: "排序", prop: "sort", minWidth: 60 },
          // { label: "推荐", prop: "is_recommend", minWidth: 60 },
          // { label: "显示", prop: "is_show", minWidth: 60 },
        ],
        //筛选对象
        filterForm: {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //组织名称
          tab: "normal", //当前筛选tab
          page_size: 15,
        },
        // 筛选缓存
        filters: {
          normal: null,
          deleted: null,
        },
        pageData: {
          page_size: 15,
          tab: "normal",
        },
        tbody: [],
        selectArr: [], //勾选的数组id
        /* 行内编辑数字验证 */
        validator: {
          type: "integer",
          min: 0,
          message: "最小不能低于0的整数",
          trigger: "blur",
        },
        pid_list: [], //上级组织列表,
        openAddCategory: false,
        showTransfer: false,
        TransferData: {
          name: "",
          id: "",
          target_id: "",
          merge_category_ids: "",
        },
      };
    },
    methods: {
      // 获取品牌列表
      getCategoryList(pageData) {
        this.tbody = [];
        this.pageData = {};
        this.loading = true;
        categoryList(pageData)
          .then((res) => {
            const { data } = res;
            this.tbody = data.list;
            this.pageData = data;
            this.filters[data.tab] = { ...this.filterForm };
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      // 勾选操作
      handleSelectionChange(selection) {
        if (!selection.length) {
          this.selectArr = [];
        } else {
          this.selectArr = [];
          // 筛选id
          selection.forEach((item) => {
            this.selectArr.push(item.id);
          });
        }
      },
      // 批量删除
      handleDel() {
        if (this.selectArr.length) {
          if (this.filterForm.tab === "normal") {
            this.$msgbox
              .confirm("确定要将选中数据移入回收站吗？", "提示", {
                type: "info",
              })
              .then((res) => {
                this.handleDelete({ id: this.selectArr });
              })
              .catch((err) => {});
          } else {
            this.$msgbox
              .confirm("确定要将选中数据永久删除吗？", "提示", {
                type: "error",
              })
              .then((res) => {
                this.handleDelete({ id: this.selectArr });
              })
              .catch((err) => {});
          }
        } else {
          this.$message.info("请选择数据项");
        }
      },
      // 点击列表删除
      deleteRow(row) {
        // if (this.filterForm.tab === "normal") {
        //   this.$msgbox
        //     .confirm("确定要将此数据移入回收站吗？", "提示", {
        //       type: "info",
        //     })
        //     .then((res) => {
        //       this.handleDelete({ id: row.id });
        //     })
        //     .catch((err) => {});
        // } else {
          this.$msgbox
            .confirm("确定要将此数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: row.id });
            })
            .catch((err) => {});
        // }
      },
      // 数据删除
      handleDelete(id) {
        this.loading = true;
        // if (this.filterForm.tab === "normal") {
        //   softDelete(id)
        //     .then((res) => {
        //       this.$message.success(res.msg);
        //       this.refreshList();
        //     })
        //     .catch((err) => {
        //       this.loading = false;
        //     });
        // } else {
          Delete(id)
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch((err) => {
              this.loading = false;
            });
        // }
      },
      // 批量还原数据
      handleReduct() {
        if (this.selectArr.length) {
          this.$msgbox
            .confirm("确定要将选中数据还原吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.Reduct({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$message.info("请选择数据项");
        }
      },
      // 点击列表还原
      ReductRow(row) {
        this.$msgbox
          .confirm("确定要将此数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: row.id });
          })
          .catch((err) => {});
      },
      // 点击列表还原
      moveRow(row) {
        this.TransferData = {
          name: row.name,
          id: row.id,
          target_id: "",
        };
        this.showTransfer = true;
      },
      // 还原数据
      Reduct(id) {
        this.loading = true;
        putBack(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      // 点击查看
      checkRow(row) {
        this.$router.push({ name: "CategoryDetail", params: { id: row.id } });
      },
      // 切换tab栏
      handleClick(e) {
        if (this.filters[e.name]) {
          this.filterForm = this.filters[e.name];
        } else {
          this.filterForm = {
            start_time: -1, //开始时间
            end_time: -1, //结束时间
            keyword: "", //组织名称
            tab: e.name, //当前筛选tab
            page: 1,
            page_size: this.pageData.per_page,
          };
        }
        this.getCategoryList(this.filterForm);
      },
      //表格排序
      sortChange(column) {
        this.filterForm.is_desc = !column.order
          ? -1
          : column.order === "ascending"
          ? 0
          : 1;
        this.filterForm.order_by = column.prop;
        this.getCategoryList(this.filterForm);
      },
      // 筛选回调
      ok(e) {
        const pageData = {};
        this.filterForm = { ...this.filterForm, ...e, page: 1 };
        this.getCategoryList(this.filterForm);
      },
      // 刷新列表
      refreshList() {
        this.getCategoryList({
          page: this.pageData.current_page,
          ...this.filterForm,
        });
      },
      // 修改列表相关状态
      changeStatus(e, key) {
        this.loading = true;
        const data = {};
        data.id = e.id;
        data[key] = e[key];
        if (key == "name") {
          // 更新推荐状态
          saveCategory(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch(() => {
              this.refreshList();
            });
        }else {
          // 更新排序状态
          updateSort(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch(() => {
              this.refreshList();
            });
        }
      },
      // 分页查询
      changPage(e) {
        this.filterForm.page_size = e.page_size;
        const data = { ...this.filterForm, ...e };
        this.getCategoryList(data);
      },
      //初始化拖拽表格
      initSortable() {
        this.$nextTick(() => {
          const sortTable = document.querySelector(
            "#sortTable .el-table__body>tbody"
          );
          console.log(sortTable);
          let that = this;
          this.$sortTable = Sortable.create(sortTable, {
            animation: 150,
            ghostClass: "sortable-ghost",
            // 拖拽移动的时候
            onMove: function ({ dragged, related }) {
              console.log(that.filterForm);
              // 筛选过的数据不能排序
              if (that.filterForm && that.filterForm.order_by) {
                return false;
              }
            },
            onEnd: (event) => {
              console.log(event.oldIndex, event.newIndex);
              if (event.oldIndex === event.newIndex) {
                return;
              }
              this.loading = true;
  
              // 拿到拖拽的item
              const oldItem = this.tbody[event.oldIndex];
              // 删除原本位置
              this.tbody.splice(event.oldIndex, 1);
              // 添加到新的位置
              this.tbody.splice(event.newIndex, 0, oldItem);
              let idArray = this.tbody.map((item) => item.id);
              //保存拖拽排序
              this.saveCateGory(idArray);
            },
          });
          //阻止火狐拖拽新建新页面
          document.body.addEventListener("drop", this.preventDrop, false);
        });
      },
      //销毁拖拽表格
      destroySortable() {
        if (this.$sortTable) this.$sortTable.destroy();
        document.body.removeEventListener("drop", this.preventDrop, false);
      },
      //阻止默认drop事件
      preventDrop(event) {
        event.preventDefault();
        event.stopPropagation();
      },
      // 保存拖拽排序
      saveCateGory(ids) {
        saveCategorySort({ ids: ids }).then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        });
      },
    },
    created() {
      this.getCategoryList(this.filterForm); //获取列表数据
    },
    mounted() {
      // 初始化拖拽表格
      this.initSortable();
    },
    components: {
      listFilter,
      Pagination,
      PopoverInputEdit,
      AddCategory,
      TransferClassify,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    .body {
      .err-box {
        ::v-deep.el-input__inner {
          color: red;
        }
      }
    }
  }
  </style>
  