<template>
  <el-dialog
    title="数据转移"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('CategoryName')"
  >
    <el-form
      size="medium"
      :model="CategoryData"
      :rules="rules"
      ref="CategoryName"
      label-width="100px"
      @submit.native.prevent
    >
      <el-form-item label="转出类型：" prop="name">
        <el-input
          type="text"
          style="width: 80%"
          v-model="CategoryData.name"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="目标类型：" prop="merge_category_ids">
        <el-cascader
          v-model="CategoryData.merge_category_ids"
          :options="categoryList"
          :props="{
            checkStrictly: true,
            children: '_children',
            value: 'id',
            label: 'name',
          }"
          clearable
        ></el-cascader>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel('CategoryName')">取消</el-button>
      <el-button type="primary" v-loading="saveLoading" @click="submit('CategoryName')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { moveCategory } from "@/modules/employ/api/employ-career-category-list.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    categoryList: {
      type: Array,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        merge_category_ids: [
          {
            required: true,
            message: "请选择目标分类",
            trigger: "blur",
          },
        ],
      },
      saveLoading:false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {};
          data.id = this.CategoryData.id;
          data.target_id =
            this.CategoryData.merge_category_ids[
              this.CategoryData.merge_category_ids.length - 1
            ];
          if (data.target_id === data.id) {
            this.$message.warning("转出分类不能与目标分类相同");
            return;
          }
          this.saveLoading = true;

          moveCategory(data)
            .then((res) => {
              this.saveLoading = false;
              this.$message.success("转移数据成功");
              this.$refs[formName].resetFields();
              this.CategoryData.merge_category_ids = [];

              this.openDialog = false;
              this.updateList();
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();

      this.openDialog = false;
      this.CategoryData.merge_category_ids = [];
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    CategoryData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
    selectArr() {
      if (this.categoryList) {
        return this.categoryList.filter(
          (item) => item.id !== this.CategoryData.id
        );
      }
    },
  },
};
</script>

<style></style>
